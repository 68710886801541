// extracted by mini-css-extract-plugin
export var header = "Q_q";
export var item = "Q_bR";
export var itemImage = "Q_cN";
export var itemIsExternal = "Q_cK";
export var itemIsInView = "Q_cH";
export var itemIsLinked = "Q_cJ";
export var itemText = "Q_cP";
export var itemTime = "Q_cL";
export var itemTimeIsBig = "Q_cM";
export var logo = "Q_bP";
export var root = "Q_n";