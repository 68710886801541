// extracted by mini-css-extract-plugin
export var header = "P_q";
export var icon = "P_bS";
export var item = "P_bR";
export var items = "P_bQ";
export var number = "P_cz";
export var root = "P_n";
export var theme1 = "P_cx";
export var theme2 = "P_bH";
export var theme3 = "P_bJ";
export var theme4 = "P_bK";
export var theme5 = "P_bL";
export var theme6 = "P_cG";